<template>
  <section class="outContainer">
    <!-- 返回 -->
    <div class="back">
      <i
        class="el-icon-back"
        @auxclick="replenishAllConsumables"
        @click="back"
      ></i>
    </div>
    <!-- <button @click="replenishAllConsumables">一键补充耗材</button> -->
    <!-- 表格数据 -->
    <div class="tableContainer">
      <el-table
        :data="tableData"
        :row-class-name="getRowClass"
        height="100%"
        style="width: 100%"
        row-key="id"
        ref="table"
        @row-click="test"
      >
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-table
              :data="scope.row.deviceConsumables"
              :header-cell-style="{ background: '#ddd' }"
              border
            >
              <el-table-column
                align="center"
                prop="name"
                label="耗材名称"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="maxConsumable"
                label="最大耗材填装量"
              >
                <template v-slot="{ row }">
                  {{
                    ['转盘工位', '化验工位'].includes(row.name)
                      ? handleNum2BinaryLength(row.maxConsumable)
                      : row.maxConsumable
                  }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="consumableRemain"
                label="耗材剩余量"
              >
                <template v-slot="{ row }">
                  {{
                    ['转盘工位', '化验工位'].includes(row.name)
                      ? handleNum2BinaryLength(row.consumableRemain)
                      : row.consumableRemain
                  }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="consumableRemain"
                label="预消耗后耗材数量"
              >
                <template v-slot="{ row }">
                  {{
                    ['转盘工位', '化验工位'].includes(row.name)
                      ? handleNum2BinaryLength(row.preConsumableRemain)
                      : row.preConsumableRemain
                  }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作" fixed="right">
                <template slot-scope="props">
                  <el-button
                    size="mini"
                    @click="consumable(props.row)"
                    v-if="scope.row.isNeedConsumable"
                    >补充耗材</el-button
                  >
                  <p v-else>--</p>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column align="center" label="设备名称" prop="name">
        </el-table-column>
        <el-table-column align="center" label="流水线id" prop="pipelineId">
          <template v-slot="{ row }">
            {{ row.pipelineId ? row.pipelineId : '空闲' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="角度" prop="curAngle">
          <template slot-scope="scope">
            {{ scope.row.curAngle != null ? scope.row.curAngle : '--' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态" prop="statusName">
          <template slot-scope="scope">
            <p>{{ scope.row.statusName }}</p>
            <p>{{ scope.row.errorCause }}</p>
            <el-button
              size="mini"
              @click="fixed(scope.row)"
              v-if="scope.row.status == 2"
              >故障修复</el-button
            >
          </template>
        </el-table-column>
        <el-table-column align="center" label="是否归位" prop="isHomingName">
          <template v-slot="{ row }">
            {{ row.isHomingName }}
            <el-button
              type="warning"
              size="mini"
              @click="homing(row)"
              v-if="showHoming(row)"
              >归位</el-button
            >
          </template>
        </el-table-column>
        <el-table-column align="center" label="修改时间" prop="updateTime">
          <template slot-scope="scope">
            {{ formatDate(scope.row.updateTime) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              v-if="
                scope.row.deviceConsumables &&
                scope.row.deviceConsumables.length > 0 &&
                !['圆盘控制器', '化验圆盘'].includes(scope.row.name)
              "
              @click="replenishRowConsumables(scope.row)"
              >一键补充耗材</el-button
            >
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" label="操作" fixed="right" width="200">
          <template slot-scope="scope">
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <!-- 补充耗材详情 -->
    <el-dialog
      :title="consumableInfo.name"
      :visible.sync="consumableOpen"
      max-width="80%"
      append-to-body
    >
      <!-- 圆盘 -->
      <div class="circleContent" v-if="consumableInfo.discStation">
        <div
          class="bigCircle"
          v-for="(item, index) in consumableInfo.discStation"
          :key="index + '_'"
        >
          第{{ index + 1 }}层
          <div v-for="(eachCircle, circleInd) in item" :key="circleInd">
            <div
              :class="eachCircle.value == 0 ? 'take computer' : 'computer'"
              @click="chooseCircle(eachCircle)"
              :style="`transform: rotateZ(${
                eachCircle.rot
              }deg) translateY(80px) rotate(${-eachCircle.rot}deg)`"
            >
              {{ circleInd + 1 }}
            </div>
          </div>
        </div>
      </div>
      <!-- 其他 -->
      <el-form label-width="130px" v-else>
        <el-form-item>
          <div class="otherConsumable">
            <div
              style="
                flex: 1;
                width: max-content;
                white-space: nowrap;
                margin-right: 30px;
              "
            >
              <p>
                原始数量：{{
                  consumableInfo.consumableRemain
                    ? consumableInfo.consumableRemain
                    : 0
                }}
              </p>
              <p>
                最大耗材填装数量：{{
                  consumableInfo.maxConsumable
                    ? consumableInfo.maxConsumable
                    : 0
                }}
              </p>
              <p>
                还能补充数量：{{
                  consumableInfo.maxConsumable - consumableInfo.consumableRemain
                }}
              </p>
              <span
                style="width: fit-content; white-space: nowrap; width: 30%"
                v-if="consumableInfo.name == '水'"
              >
                重量：{{
                  consumableInfo.consumableRemain
                    ? (consumableInfo.consumableRemain / 100).toFixed(1)
                    : 0
                }}g
              </span>
            </div>
            <el-input
              type="number"
              v-model="consumableInfo.replenishQuantity"
              placeholder="补充数量"
            ></el-input>
            <!-- 还能补充数量：{{
                consumableInfo.maxConsumable - consumableInfo.consumableRemain
              }} -->
            <div
              v-if="consumableInfo.name == '水'"
              style="width: 200px; margin-left: 10px"
            >
              (100 = 1g)
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="consumableOpen = false">关闭</el-button>
        <el-button @click="submitConsumable">提交</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import dayjs from 'dayjs'
import {
  resetSingleCabinetDevice,
  getDeviceList,
  replenishConsumable,
  fixBug,
} from '@/api/public.js'
import { timeToTime } from '@/api/publicFun.js'
import { createLogger } from 'vuex'
export default {
  data() {
    return {
      tableData: [], // 表格数据
      consumableOpen: false, // 是否打开补充耗材弹框
      consumableInfo: {
        // 当前补充耗材信息
        originalQuantity: 0,
        name: '',
        deviceId: '',
        replenishQuantity: 0,
        discStation: [],
        originalDiscStation: [],
      },
      cabinetId: null, // 柜机ID
      systemId: null, // 系统ID
      originalDiscStation: null, // 原始圆盘工位
      currRot: 0, // 圆盘旋转角度
    }
  },

  components: {},

  computed: {
    showHoming() {
      return row => {
        const arr = [
          '平台',
          '圆盘控制器',
          '落杯器',
          '圆盘震动器',
          '化验圆盘',
          '化验杯选择圆盘',
          '落杯通道封闭设备',
          '丢弃杯子通道封闭设备',
        ]
        // if (arr.includes(row.name)) {
        if (row.showHomingBtn) {
          return true
        } else {
          return false
        }
        // console.log(row, 'row')
      }
    },
  },

  mounted() {
    this.cabinetId = this.$route.query.cabinetId
    this.systemId = this.$route.query.systemId
    this.init()
  },

  methods: {
    test(row) {
      console.log(row, 'row')
      const table = this.$refs.table
      table.toggleRowExpansion(row, true) // 展开每一行
    },
    handleNum2BinaryLength(num) {
      console.log(num, 'num') // 输出原始数字
      const binaryString = num.toString(2) // 将数字转换为二进制字符串
      console.log(binaryString, 'binary') // 输出二进制字符串形式
      const onesCount = binaryString.split('').filter(bit => bit === '1').length // 统计1的数量
      console.log(onesCount, 'ones count') // 输出1的数量
      return onesCount
    },
    // 判断当前行是否有需要补充的耗材列表
    getRowClass(row) {
      if (!row.row.deviceConsumables || row.row.deviceConsumables.length == 0) {
        return 'row-expand-cover'
      }
    },
    // 初始化获取设备列表
    async init() {
      let data = {
        param: this.systemId,
      }
      await getDeviceList(data).then(res => {
        if (res.success) {
          this.tableData = res.data

          this.$nextTick(() => {
            const table = this.$refs.table
            console.log(this.tableData, 'tableData')
            this.tableData
              .filter(item => item.deviceConsumables)
              .forEach(row => {
                table.toggleRowExpansion(row, true) // 展开每一行
              })
          })
        } else {
          this.tableData = []
        }
      })
    },
    // 故障修复
    fixed(row) {
      this.$confirm('确认故障已修复吗？').then(async () => {
        await fixBug({ param: row.id }).then(res => {
          this.$message({
            message: res.msg,
            type: res.success ? 'success' : 'fail',
          })
          this.init()
        })
      })
    },
    // 返回
    back() {
      this.$router.go(-1)
    },
    // 打开耗材详情
    consumable(row) {
      this.consumableInfo = { ...row }
      this.consumableInfo.originalDiscStation = this.consumableInfo.discStation
        ? [...this.consumableInfo.discStation]
        : this.consumableInfo.discStation
      if (this.consumableInfo.discStation) {
        this.consumableInfo.discStation.forEach((element, index) => {
          let each = []
          element.forEach(circle => {
            each.push({ value: circle })
            element = [...each]
          })
          this.$nextTick(() => {
            this.currRot = 360 / element.length
            element.forEach((item, index) => {
              this.$set(item, 'rot', this.currRot * index)
            })
          })
          this.consumableInfo.discStation[index] = [...element]
        })
        this.consumableInfo.discStation = [...this.consumableInfo.discStation]
      }
      this.consumableInfo = { ...this.consumableInfo }
      this.consumableOpen = true
    },
    // 点击选择圆盘
    chooseCircle(eachCicle) {
      eachCicle.value = eachCicle.value == 1 ? 0 : 1
      this.consumableInfo.discStation = [...this.consumableInfo.discStation]
    },
    //一件补充耗材
    replenishAllConsumables() {
      this.tableData.forEach(device => {
        if (device.deviceConsumables && device.deviceConsumables.length > 0) {
          device.deviceConsumables.forEach(async deviceConsumable => {
            let data = {
              param: {
                consumableId: deviceConsumable.id,
                replenishQuantity:
                  deviceConsumable.maxConsumable -
                  deviceConsumable.consumableRemain,
                originalQuantity: deviceConsumable.consumableRemain,
              },
            }
            if (data.param.replenishQuantity == 0) {
              return
            }
            await replenishConsumable(data).then(res => {
              this.$message({
                message: res.msg,
                type: res.success ? 'success' : 'fail',
              })
              this.init()
            })
          })
        }
      })
    },
    //一件补充单行耗材
    // replenishRowConsumables(row) {
    //   row.deviceConsumables.forEach(async deviceConsumable => {
    //     let data = {
    //       param: {
    //         consumableId: deviceConsumable.id,
    //         replenishQuantity:
    //           deviceConsumable.maxConsumable -
    //           deviceConsumable.consumableRemain,
    //         originalQuantity: deviceConsumable.consumableRemain,
    //       },
    //     }
    //     if (data.param.replenishQuantity == 0) {
    //       return
    //     }
    //     await replenishConsumable(data).then(res => {
    //       this.$message({
    //         message: res.msg,
    //         type: res.success ? 'success' : 'fail',
    //       })
    //     })
    //   })
    //   this.init()
    // },
    //一件补充单行耗材
    async replenishRowConsumables(row) {
      const promises = row.deviceConsumables.map(deviceConsumable => {
        let data = {
          param: {
            consumableId: deviceConsumable.id,
            replenishQuantity:
              deviceConsumable.maxConsumable -
              deviceConsumable.consumableRemain,
            originalQuantity: deviceConsumable.consumableRemain,
          },
        }

        if (data.param.replenishQuantity == 0) {
          return Promise.resolve() // 返回一个已完成的 Promise
        }

        // 返回 replenishConsumable 的 Promise，并处理结果
        return replenishConsumable(data)
          .then(res => {
            this.$message({
              message: res.msg,
              type: res.success ? 'success' : 'fail',
            })
          })
          .catch(error => {
            console.log(error, 'error')
            this.$message({
              message: '补充耗材失败',
              type: 'error',
            })
          })
      })

      console.log(promises, 'promises')
      // 等待所有 Promise 完成（无论成功或失败）
      await Promise.allSettled(promises)
      this.init()
    },

    // 确认提交耗材
    submitConsumable() {
      this.$confirm('确认提交吗？').then(async () => {
        let discStation = null
        if (this.consumableInfo.originalDiscStation) {
          discStation = []
          this.consumableInfo.discStation.forEach(element => {
            let eachCicle = []
            element.forEach(ele => {
              eachCicle.push(ele.value)
            })
            discStation.push(eachCicle)
          })
        }
        let data = {
          param: {
            consumableId: this.consumableInfo.id,
            discStation: this.consumableInfo.originalDiscStation
              ? discStation
              : null,
            originalDiscStation: this.consumableInfo.originalDiscStation,
            replenishQuantity: Number(this.consumableInfo.replenishQuantity),
            originalQuantity: this.consumableInfo.consumableRemain,
          },
        }
        await replenishConsumable(data).then(res => {
          this.$message({
            message: res.msg,
            type: res.success ? 'success' : 'fail',
          })
          this.consumableOpen = !res.success
          this.init()
        })
      })
    },
    // 设备归位
    async homing(row) {
      await resetSingleCabinetDevice({ param: row.id }).then(res => {
        console.log(res, 'res')
        if (res.success) {
          this.init()
          this.$message({
            message: row.name + '归位成功',
            type: 'success',
          })
        } else {
          this.$message({
            message: row.name + '归位失败',
            type: 'error',
          })
        }
      })
    },
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
    },
  },
}
</script>

<style scoped lang="scss">
/deep/ .el-table .row-expand-cover .cell .el-table__expand-icon {
  display: none;
}
/deep/ .el-dialog {
  max-height: calc(90% - 15vh);
  overflow: auto;
}
.back {
  width: fit-content;
}
.otherConsumable {
  display: flex;
  align-items: center;
  width: 100%;
  p {
    width: 50%;
    text-align: center;
  }
}
.circleContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.computer {
  height: 30px;
  width: 30px;
  line-height: 30px;
  border-radius: 50%;
  position: absolute;
  left: 85px;
  top: 85px;
  background: #fff;
  z-index: 3000;
  transform-origin: 0 0;
  color: #000;
  cursor: pointer;
  text-align: center;
}

/deep/ .el-form-item {
  width: 100%;
}
/deep/ .el-form-item__content {
  display: flex;
  margin-left: 10px !important;
  // width: calc(100% - 150px);
}
/deep/ .el-form-item__label {
  font-weight: 600;
  font-size: 18px;
  color: #0b096e;
}
.doors {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .eachDoor {
    width: 50%;
    height: 100px;
    text-align: center;
    line-height: 100px;
    border: solid #000 1px;
  }
}
.bigCircle {
  background-color: #504d4d;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  padding: 30px;
  box-sizing: border-box;
  position: relative;
  color: #fff;
  text-align: center;
  line-height: 140px;
  .smallCircle,
  span {
    margin: 0;
    list-style-type: none;
    padding: 0;
  }
  .smallCircle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    background-color: #fff;
  }
  span {
    display: inline-block;
    width: 40px;
    height: 40px;
    position: absolute;
    background-color: #fff;
    border-radius: 50%;
    line-height: 40px;
    text-align: center;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
    color: #000;
    cursor: pointer;
  }
  span:nth-of-type(2) {
    top: 15%;
    left: 85%;
  }
  span:nth-of-type(3) {
    top: 50%;
    left: 100%;
  }
  span:nth-of-type(4) {
    top: 85%;
    left: 85%;
  }
  span:nth-of-type(5) {
    top: 100%;
    left: 50%;
  }
  span:nth-of-type(6) {
    top: 85%;
    left: 15%;
  }
  span:nth-of-type(7) {
    top: 50%;
    left: 0%;
  }
  span:nth-of-type(8) {
    top: 15%;
    left: 15%;
  }
  .take {
    background: red;
  }
}
</style>
